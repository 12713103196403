import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/doctors");
  }
  get(id) {
    return axios.get(`/doctors/${id}`);
  }
  create(data) {
    return axios.post("/doctors", data);
  }
  update(id, data) {
    return axios.put(`/doctors/${id}`, data);
  }
}
export default new DataService();